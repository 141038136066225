import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../../components/Typography/Typography";
import MainLayout from "../../components/mainlayout";
import {
  mobileViewBreakpoint,
  extraLargeDesktopViewBreakpoint
} from "../../components/helper";

import Summary from "../../components/CaseStudy/Summary/Summary";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },

  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));
const Article = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "casestudy/pga/golfbanner.jpg" }) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Custom VIP transport solution for PGA"}
      banner={
        <StaticImage
          src="../../assets/images/casestudy/pga/golfbanner.jpg"
          draggable="false"
          alt="golf ball"
        />
      }
      breadcrumbs={[{ label: "PGA European Tour" }]}
      metaTitle="PGA European Tour Jeddah Case Study"
      metaDescription="Check out how our team built a custom VIP transport solution for our client that included 150+ mixed fleet vehicles."
      shareImage={data.bannerImage.childImageSharp.fixed.src}
    >
      <div className={classes.mainContainer}>
        <div className={classes.caseStudyContainer}>
          <Typography customVariant={"h5Regular"}>
            The Saudi International powered by SBIA is a European Tour golf
            tournament held at Royal Greens Golf and Country Club in King
            Abdullah Economic City (KAEC), Saudi Arabia. Established in 2019, it
            is the first European Tour event to be played in Saudi Arabia, and
            one of six European Tour events staged in the Arabian Peninsula
            countries.
          </Typography>
        </div>
        <Summary
          title={"Overview"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../../assets/images/casestudy/pga/Picture5.jpg"
              draggable="false"
              alt="golf course with sea view"
            />
          }
          customWidth={600}
          customHeight={960}
          link={undefined}
          description={
            <>
              <p>
                Our team built a custom VIP transport solution for our client,
                IMG, and The Saudi Golf Federation (SGF) that included 150+
                mixed fleet vehicles.
              </p>
              <p>
                The initial phase included integrating with the project’s team
                to design and operate a fleet-staging yard close to the central
                accommodations within KAEC, and create arrivals and departures
                dispatch manifests, daily schedules, a driver welfare program,
                and fleet servicing schedules.
              </p>
              <p>
                After this we conducted reviews and made calculations against
                the flight arrivals manifest, using private aviation to create
                personalized services to match the project vision. We designed
                and implemented daily run sheets, provided an operations team to
                manage arrivals, departures, daily transfers, and fleet
                management, and ran over 300 arrivals with an average of 125
                daily trips or transfers.
              </p>
              <p>
                We had direct liaison with SGF, IMG, and KAEC Security to
                organize and deliver protocol escorts for guests’ movements over
                the four-day event.
              </p>
            </>
          }
        />

        <Summary
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/pga/Picture6.jpg"
              draggable="false"
              alt="golf course with flag"
            />
          }
          customWidth={600}
          customHeight={1000}
          link={undefined}
          description={
            <>
              <Typography customVariant={"h2Bold"}>Management</Typography>
              <p>
                To design and plan this event, we needed to conduct several
                tasks in parallel with managing internal and external
                stakeholders and suppliers. Our procurement and commercial
                secured 85+ BMWs and other vehicles while the transport managers
                organized fleet allocations and usage models based on the daily
                transfers, arrivals, and departures manifest.
              </p>
              <Typography customVariant={"h2Bold"}>Challenges</Typography>
              <p>
                Fleet allocation was the most significant challenge as it was
                critical to maintain the highest levels of services and
                equipment standards while staying within budget. The second
                challenge was the ever-changing requirement for players and ad-
                hoc SGF guests to attend simultaneous events within the invited
                group. The information flow consisted of three hospitality
                programs – for SIBA, the SGF and PGA – under one umbrella.
              </p>
              <Typography customVariant={"h2Bold"}>Solutions</Typography>
              <p>
                Our solution was to maintain a primary fleet with a guaranteed
                on-call contingency fleet whose drivers were already vetted and
                trained in customer service. We also conducted dry-run training
                with the primary fleet. Additional fleet dispatchers were
                activated to cover the three hospitality groups, with airport
                operations incorporated into 24-hour service coverage to
                accommodate all guest requests at all times.
              </p>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
